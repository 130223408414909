import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import ProtectedRoute from '../../auth/ProtectedRoute';

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/home`}
          component={lazy(() => import(`./home`))}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/productions`}
          component={lazy(() => import(`./productions`))}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/productions/:id`}
          component={lazy(() => import(`./productions/ProductionContents`))}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/box-office/add`}
          component={lazy(() => import(`./boxOffice/BoxOfficeMain`))}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/box-office/upcoming`}
          component={lazy(() => import(`./boxOffice/BoxOfficeUpcoming`))}
        />
        <ProtectedRoute
          exact
          path={`${APP_PREFIX_PATH}/box-office/past`}
          component={lazy(() => import(`./boxOffice/BoxOfficePast`))}
        />
        {/* <ProtectedRoute
          path={`${APP_PREFIX_PATH}/apps`}
          component={lazy(() => import(`./apps`))}
        /> */}
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
