import React from 'react';

export default function Footer() {
  return (
    <footer className="footer">
      <span style={{ fontSize: 10 + 'px' }}>
        Copyright &copy; 2020&ndash;{`${new Date().getFullYear()}`} Any One
        Thing. All rights reserved.
        <br />
        <span className="font-weight-semibold">
          Happy {`${new Date().toLocaleString('en-us', { 'weekday': 'long' })}`}
          !
        </span>
      </span>
    </footer>
  );
}
