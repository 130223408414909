import React, { Component } from 'react';
// import { SettingOutlined } from '@ant-design/icons';
import { Drawer, Menu } from 'antd';
import ThemeConfigurator from './ThemeConfigurator';
import { connect } from 'react-redux';

import LogoutButton from '../../views/auth-views/components/LogoutButton';
import TimeZoneSelect from '../connected-components/TimeZoneSelect/TimeZoneSelect.js';
export class NavPanel extends Component {
  state = { visible: false };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <>
        <Menu mode="horizontal">
          <Menu.Item key="0">
            <TimeZoneSelect />
          </Menu.Item>
          <Menu.Item
            key="1"
            // onClick={() => {
            //   onToggle();
            // }}
          >
            <LogoutButton />
          </Menu.Item>
        </Menu>
        <Drawer
          title="Theme Config"
          placement="right"
          width={350}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <ThemeConfigurator />
        </Drawer>
      </>
    );
  }
}

const mapStateToProps = ({ theme, boxoffice }) => {
  const { locale } = theme;
  const { timezone } = boxoffice;
  return { locale, timezone };
};

export default connect(mapStateToProps)(NavPanel);
