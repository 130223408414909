import axios from 'axios';
import {
  GET_ALL_SHOWS,
  DELETE_SHOW,
  // ADD_SHOW,
  CLEAR_ALL_SHOWS,
  REVIVE_SHOW,
  BOXOFFICE_ERROR,
  CHANGE_TIMEZONE,
} from '../constants/BoxOffice';
import moment from 'moment';
import { API_BASE_URL } from '../../configs/AppConfig';

export const getAllShows = (productions) => async (dispatch) => {
  try {
    dispatch({
      type: CLEAR_ALL_SHOWS,
    })
    let showsArray = [[], []];
    let upcomingShows = [];
    let pastShows = [];
    const productionShows = productions.map(async (production) => {
      const showsRes = await axios.get(
        `${API_BASE_URL}/productions/${production.id}/shows.json`
      );
      if (showsRes) {
        showsRes.data.forEach((show) => {
          const d = new Date();
          const t = d.getTime();
          if (moment(show.showtime).local().format('x') < t) {
            pastShows.push(show);
          } else {
            upcomingShows.push(show);
          }
        });
      }
      return true;
    });

    await Promise.all(productionShows);

    showsArray[0] = upcomingShows;
    showsArray[1] = pastShows;

    dispatch({
      type: GET_ALL_SHOWS,
      payload: showsArray,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: BOXOFFICE_ERROR,
      payload: err,
    });
  }
};

export const deleteShow = (showId) => async (dispatch) => {
  try {
    const ticketsRes = await axios.get(
      `${API_BASE_URL}/shows/${showId}/seats.json`
    );

    if (ticketsRes.data) {
      const requests = [];

      for (let i = 0; i < ticketsRes.data.length; i++) {
        requests.push(
          axios.delete(`${API_BASE_URL}/seats/${ticketsRes.data[i].id}.json`)
        );
      }
      // Map array of responses to orderInfo
      return Promise.all(requests).then(async (results) => {
        const deleteRes = await axios.delete(
          `${API_BASE_URL}/shows/${showId}.json`
        );
        if (parseInt(deleteRes.status) === 204) {
          dispatch({
            type: DELETE_SHOW,
            payload: { id: showId },
          });
        }
      });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: BOXOFFICE_ERROR,
      payload: err,
    });
  }
};

export const reviveShow = (show, values) => async (dispatch) => {
  try {
    let showObject = {};

    showObject['showtime'] = values['date-time-picker'];

    if (
      !(
        showObject &&
        Object.keys(showObject).length === 0 &&
        showObject.constructor === Object
      )
    ) {
      const editRes = await axios.put(
        `${API_BASE_URL}/shows/${show.id}.json`,
        showObject
      );
      if (editRes.data) {
        dispatch({
          type: REVIVE_SHOW,
          payload: editRes.data,
        });
      }
    }
  } catch (err) {
    console.log(err);

    dispatch({
      type: BOXOFFICE_ERROR,
      payload: err,
    });
  }
};

export const changeTimezone = (value) => async (dispatch) => {
  try {
    dispatch({
      type: CHANGE_TIMEZONE,
      payload: value,
    });
  } catch (err) {
    console.log(err);

    dispatch({
      type: BOXOFFICE_ERROR,
      payload: err,
    });
  }
};
