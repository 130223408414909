import { combineReducers } from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import Production from './Production';
import Cue from './Cue';
import EditCue from './EditCue';
import BoxOffice from './BoxOffice';

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  content: Production,
  cue: Cue,
  editCue: EditCue,
  boxoffice: BoxOffice,
});

export default reducers;
