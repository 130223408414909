import axios from 'axios';
import {
  GET_ALL_PRODUCTIONS,
  GET_ALL_PRODUCTION_SCENES_AND_CUES,
  CLEAR_PRODUCTIONS,
  CLEAR_PRODUCTION,
  PRODUCTIONS_ERROR,
  ADD_SCENE,
  EDIT_SCENE,
  DELETE_SCENE,
} from '../constants/Production';
import { API_BASE_URL } from '../../configs/AppConfig';
import { notification } from 'antd';

export const getAllProductions = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_PRODUCTIONS });

    const res = await axios.get(`${API_BASE_URL}/productions.json`);
    dispatch({
      type: GET_ALL_PRODUCTIONS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PRODUCTIONS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const getAllProductionScenesAndCues = (production_id) => async (
  dispatch
) => {
  try {
    dispatch({ type: CLEAR_PRODUCTION });

    const productionRes = await axios.get(
      `${API_BASE_URL}/productions/${production_id}.json`
    );

    const res = await axios.get(
      `${API_BASE_URL}/productions/${production_id}/scenes.json`
    );

    if (res.data) {
      const sceneData = res.data;
      const scenes = sceneData.sort((a, b) =>
        a.scene_index > b.scene_index ? 1 : -1
      );

      const sceneAndQueueData = scenes.map(async (scene) => {
        const sceneRes = await axios.get(
          `${API_BASE_URL}/scenes/${scene.id}/cues.json`
        );

        return { ...scene, cues: sceneRes.data };
      });

      const payloadScenesAndQueues = await Promise.all(sceneAndQueueData);
      const payloadData = {
        ...productionRes.data,
        scenes: payloadScenesAndQueues,
      };

      dispatch({
        type: GET_ALL_PRODUCTION_SCENES_AND_CUES,
        payload: payloadData,
      });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: PRODUCTIONS_ERROR,
      payload: err,
    });
  }
};

const openNotificationWithIcon = (type, name) => {
  notification[type]({
    message: `Your Scene "${name}" has been created!`,
    description:
      "You can begin adding Cues using the 'Add Cue' button. You'll find the button alongside the name of your scene, in the list below.",
  });
};

const confirmSceneEdit = (type, name) => {
  notification[type]({
    message: `Scene "${name}" has been saved!`,
  });
};

const confirmSceneDelete = (type) => {
  notification[type]({
    message: `The scene has been deleted!`,
  });
};

export const saveNewScene = (sceneData, productionId) => async (dispatch) => {
  try {
    const sceneRes = await axios.post(
      `${API_BASE_URL}/productions/${productionId}/scenes.json`,
      sceneData
    );
    if (sceneRes) {
      const payload = { ...sceneData, id: sceneRes.data.id, cues: [] };
      openNotificationWithIcon('success', sceneData.name);
      dispatch({
        type: ADD_SCENE,
        payload,
      });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: PRODUCTIONS_ERROR,
      payload: err,
    });
  }
};

export const editScene = (sceneData) => async (dispatch) => {
  try {
    const sceneRes = await axios.put(
      `${API_BASE_URL}/scenes/${sceneData.id}.json`,
      sceneData
    );
    if (sceneRes) {
      const payload = { ...sceneData };
      confirmSceneEdit('success', sceneData.name);
      dispatch({
        type: EDIT_SCENE,
        payload,
      });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: PRODUCTIONS_ERROR,
      payload: err,
    });
  }
};

export const deleteScene = (sceneId) => async (dispatch) => {
  try {
    const sceneRes = await axios.delete(
      `${API_BASE_URL}/scenes/${sceneId}.json`
    );
    if (sceneRes) {
      const payload = { id: sceneId };
      confirmSceneDelete('success');
      dispatch({
        type: DELETE_SCENE,
        payload,
      });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: PRODUCTIONS_ERROR,
      payload: err,
    });
  }
};
