import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';

const LogoutButton = () => {
  const { logout } = useAuth0();
  return (
    <Button
      type="primary"
      icon={<LogoutOutlined />}
      danger
      onClick={() =>
        logout({
          returnTo: window.location.origin,
        })
      }
    >
      Log Out
    </Button>
  );
};

export default LogoutButton;
