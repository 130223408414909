import {
  GET_ALL_SHOWS,
  CLEAR_ALL_SHOWS,
  ADD_SHOW,
  DELETE_SHOW,
  EDIT_SHOW,
  REVIVE_SHOW,
  CHANGE_TIMEZONE,
  BOXOFFICE_ERROR,
} from '../constants/BoxOffice';

const initialState = {
  pastShows: [],
  upcomingShows: [],
  showsArray: [],
  timezone: '',
  loading: true,
  error: {},
};

const BoxOffice = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_SHOWS:
      // let test = payload[0].map((showObject) => {
      //   console.log(showObject);
      //   return showObject;
      // });
      // console.log(test);

      return {
        ...state,
        upcomingShows: payload[0],
        pastShows: payload[1],
        loading: false,
      };
    case CLEAR_ALL_SHOWS:
      return {
        ...state,
        upcomingShows: [],
        pastShows: [],
        loading: true,
      }
    case ADD_SHOW:
      return {
        ...state,
        upcomingShows: {},
        loading: true,
      };
    case EDIT_SHOW:
      return {
        ...state,
        upcomingShows: {},
        loading: true,
      };
    case DELETE_SHOW:
      return {
        ...state,
        upcomingShows: state.upcomingShows.filter(
          (show) => show.id !== payload.id
        ),
        loading: false,
      };
    case REVIVE_SHOW:
      return {
        ...state,
        upcomingShows: [...state.upcomingShows, payload],
        pastShows: state.pastShows.filter((show) => show.id !== payload.id),
        loading: false,
      };
    case CHANGE_TIMEZONE:
      return {
        ...state,
        timezone: payload,
        loading: false,
      };
    case BOXOFFICE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default BoxOffice;
