import {
  EDIT_CUE_ERROR,
  CLEAR_EDIT_CUE,
  UPDATE_EDIT_CUE_PROPERTY,
  UPDATE_EDIT_CUE_VARIATION_PROPERTY,
  GET_EDIT_CUE,
} from '../constants/EditCue';

const initialState = {
  editCueData: {
    id: null,
    name: '',
    scene_id: null,
    step_index: null,
    duration: null,
    fade_in_visuals: false,
    fade_out_visuals: false,
    variations: [],
  },
  loading: true,
  error: {},
};

const EditCue = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_EDIT_CUE:
      return {
        ...state,
        editCueData: payload,
        loading: false,
      };
    case UPDATE_EDIT_CUE_PROPERTY:
      return {
        ...state,
        editCueData: {
          ...state.editCueData,
          ...payload,
        },

        loading: false,
      };
    case UPDATE_EDIT_CUE_VARIATION_PROPERTY:
      return {
        ...state,
        editCueData: {
          ...state.editCueData,
          variations: payload,
        },

        loading: false,
      };
    case CLEAR_EDIT_CUE:
      return {
        ...state,
        editCueData: {
          id: null,
          name: '',
          scene_id: null,
          step_index: null,
          duration: null,
          fade_in_visuals: false,
          fade_out_visuals: false,
          variations: [],
        },
        loading: true,
      };
    case EDIT_CUE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default EditCue;
