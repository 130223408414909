import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Grid } from 'antd';
import IntlMessage from '../util-components/IntlMessage';
import Icon from '../util-components/Icon';
import navigationConfig from 'configs/NavigationConfig';
import { connect } from 'react-redux';
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from 'constants/ThemeConstant';
import utils from 'utils';
import { onMobileNavToggle } from 'redux/actions/Theme';
import {
  DashboardOutlined,
  VideoCameraOutlined,
  BankOutlined,
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

import { useAuth0 } from '@auth0/auth0-react';

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

// const setDefaultOpen = (key) => {
//   let keyList = [];
//   let keyString = '';
//   if (key) {
//     const arr = key.split('-');
//     for (let index = 0; index < arr.length; index++) {
//       const elm = arr[index];
//       index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
//       keyList.push(keyString);
//     }
//   }
//   return keyList;
// };

const SideNavContent = (props) => {
  const {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
    onMobileNavToggle,
    productions,
  } = props;
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false);
    }
  };

  const { isAuthenticated } = useAuth0();

  const productionItems = productions.map((production) => {
    return {
      key: `production-${production.id}`,
      path: `${APP_PREFIX_PATH}/productions/${production.id}`,
      title: `${production.name}`,
      icon: '',
      breadcrumb: false,
      submenu: [],
    };
  });

  const dashBoardNavTree = isAuthenticated
    ? [
        {
          key: 'home',
          path: `${APP_PREFIX_PATH}/home`,
          title: 'Home',
          icon: DashboardOutlined,
          breadcrumb: false,
          submenu: [],
        },
        {
          key: 'box-office-section',
          path: `${APP_PREFIX_PATH}/productions`,
          title: ' ',
          icon: VideoCameraOutlined,
          breadcrumb: true,
          submenu: [
            {
              key: 'box-office-main',
              path: `${APP_PREFIX_PATH}/productions`,
              title: 'Box Office',
              icon: BankOutlined,
              breadcrumb: true,
              submenu: [
                {
                  key: 'box-office-new',
                  path: `${APP_PREFIX_PATH}/box-office/add`,
                  title: 'New Booking',
                  icon: '',
                  breadcrumb: false,
                  submenu: [],
                },
                {
                  key: 'box-office-upcoming',
                  path: `${APP_PREFIX_PATH}/box-office/upcoming`,
                  title: 'Upcoming Shows',
                  icon: '',
                  breadcrumb: false,
                  submenu: [],
                },
                {
                  key: 'box-office-past',
                  path: `${APP_PREFIX_PATH}/box-office/past`,
                  title: 'Past Shows',
                  icon: '',
                  breadcrumb: false,
                  submenu: [],
                },
              ],
            },
          ],
        },
        {
          key: 'productions',
          path: `${APP_PREFIX_PATH}/productions`,
          title: ' ',
          icon: VideoCameraOutlined,
          breadcrumb: true,
          class: 'd-none',
          submenu: [
            {
              key: 'productions-own',
              path: `${APP_PREFIX_PATH}/productions`,
              title: 'Productions',
              icon: VideoCameraOutlined,
              breadcrumb: true,
              submenu: productionItems,
            },
          ],
        },
      ]
    : [
        {
          key: 'home',
          path: `${APP_PREFIX_PATH}/home`,
          title: 'Home',
          icon: DashboardOutlined,
          breadcrumb: false,
          submenu: [],
        },
      ];

  return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? 'light' : 'dark'}
      mode="inline"
      style={{ height: '100%', borderRight: 0 }}
      defaultSelectedKeys={[routeInfo?.key]}
      // defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      defaultOpenKeys={['productions-own', 'box-office-main']}
      className={hideGroupTitle ? 'hide-group-title' : ''}
    >
      {dashBoardNavTree.map((menu) =>
        menu.submenu.length > 0 ? (
          <Menu.ItemGroup
            key={menu.key}
            title={setLocale(localization, menu.title)}
            className={menu.key === 'productions' ? 'd-none' : ''}
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  key={subMenuFirst.key}
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      {subMenuSecond.icon ? (
                        <Icon type={subMenuSecond?.icon} />
                      ) : null}
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link
                        onClick={() => closeMobileNav()}
                        to={subMenuSecond.path}
                      />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link
                    onClick={() => closeMobileNav()}
                    to={subMenuFirst.path}
                  />
                </Menu.Item>
              )
            )}
          </Menu.ItemGroup>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? (
              <Link onClick={() => closeMobileNav()} to={menu.path} />
            ) : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const TopNavContent = (props) => {
  const { topNavColor, localization } = props;
  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const MenuContent = (props) => {
  useEffect(() => {}, [props.productions]);
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} />
  ) : (
    <TopNavContent {...props} />
  );
};

const mapStateToProps = ({ theme, content }) => {
  const { sideNavTheme, topNavColor } = theme;
  const { productions } = content;
  return { sideNavTheme, topNavColor, productions };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
