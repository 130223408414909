import {
  ADD_CUE,
  ADD_SCENE,
  GET_ALL_PRODUCTIONS,
  GET_ALL_PRODUCTION_SCENES_AND_CUES,
  CLEAR_PRODUCTIONS,
  CLEAR_PRODUCTION,
  PRODUCTIONS_ERROR,
  EDIT_PRODUCTION_CUE_INFO,
  EDIT_SCENE,
  DELETE_SCENE,
} from '../constants/Production';

const initialState = {
  productions: [],
  production: {},
  loading: true,
  error: {},
};

const Production = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_PRODUCTIONS:
      return {
        ...state,
        productions: payload,
        loading: false,
      };
    case GET_ALL_PRODUCTION_SCENES_AND_CUES:
      return {
        ...state,
        production: payload,
        loading: false,
      };
    case EDIT_PRODUCTION_CUE_INFO:
      return {
        ...state,
        production: {
          ...state.production,
          scenes: state.production.scenes.map((scene) =>
            scene.id === payload.scene_id
              ? {
                  ...scene,
                  cues: scene.cues.map((cue) =>
                    cue.id === payload.id ? payload : cue
                  ),
                }
              : scene
          ),
        },
      };
    case ADD_CUE:
      return {
        ...state,
        production: {
          ...state.production,
          scenes: state.production.scenes.map((scene) =>
            scene.id === payload.scene_id
              ? {
                  ...scene,
                  cues: [...scene.cues, payload],
                }
              : scene
          ),
        },
      };
    case ADD_SCENE:
      return {
        ...state,
        production: {
          ...state.production,
          scenes: [...state.production.scenes, payload],
        },
      };
    case EDIT_SCENE:
      return {
        ...state,
        production: {
          ...state.production,
          scenes: state.production.scenes.map((scene) => {
            if (scene.id === payload.id) {
              return payload;
            } else {
              return scene;
            }
          }),
        },
      };
    case DELETE_SCENE:
      return {
        ...state,
        production: {
          ...state.production,
          scenes: state.production.scenes.filter((scene) => {
            return scene.id !== payload.id;
          }),
        },
      };
    case CLEAR_PRODUCTIONS:
      return {
        ...state,
        productions: [],
        loading: false,
      };
    case CLEAR_PRODUCTION:
      return {
        ...state,
        production: {},
        loading: false,
      };
    case PRODUCTIONS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default Production;
