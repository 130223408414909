import { CLEAR_CUE, CUE_ERROR, GET_ALL_CUE_INFO } from '../constants/Cue';

const initialState = {
  cue: {
    id: null,
    name: '',
    scene_id: null,
    step_index: null,
    duration: null,
    fade_in_visuals: false,
    fade_out_visuals: false,
    variations: [],
  },
  loading: true,
  error: {},
};

const Cue = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_CUE_INFO:
      return {
        ...state,
        cue: payload,
        loading: false,
      };
    case CLEAR_CUE:
      return {
        ...state,
        cue: {},
        loading: true,
      };
    case CUE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default Cue;
