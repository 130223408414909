export const ADD_CUE = 'ADD_CUE';
export const ADD_SCENE = 'ADD_SCENE';
export const EDIT_SCENE = 'EDIT_SCENE';
export const DELETE_SCENE = 'DELETE_SCENE';
export const GET_ALL_PRODUCTIONS = 'GET_ALL_PRODUCTIONS';
export const GET_PRODUCTION = 'GET_PRODUCTION';
export const CLEAR_PRODUCTION = 'CLEAR_PRODUCTION';
export const CLEAR_PRODUCTIONS = 'CLEAR_PRODUCTIONS';
export const PRODUCTIONS_ERROR = 'PRODUCTIONS_ERROR';
export const EDIT_PRODUCTION_CUE_INFO = 'EDIT_PRODUCTION_CUE_INFO';
export const GET_ALL_PRODUCTION_SCENES_AND_CUES =
  'GET_ALL_PRODUCTION_SCENES_AND_CUES';
