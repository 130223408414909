const dev = {
  API_ENDPOINT_URL: 'http://localhost:3001/api/v1',
  APP_PREFIX: '/client',
  REACT_APP_AUTH0_DOMAIN: 'login.onething.org',
  REACT_APP_AUTH0_CLIENT_ID: 'MKbajiBsMm0nzHzbSFIEVoAI7HfWo3lU',
};

const staging = {
  API_ENDPOINT_URL: 'https://earlything.org/api/v1',
  APP_PREFIX: '/client',
  REACT_APP_AUTH0_DOMAIN: 'login.onething.org',
  REACT_APP_AUTH0_CLIENT_ID: 'K9tYIopbtRCzwNVTBUKpMwDrbOCqx7Bq',
};

const prod = {
  API_ENDPOINT_URL: 'https://onething.org/api/v1',
  APP_PREFIX: '/client',
  REACT_APP_AUTH0_DOMAIN: 'login.onething.org',
  REACT_APP_AUTH0_CLIENT_ID: 'K9tYIopbtRCzwNVTBUKpMwDrbOCqx7Bq',
};

const test = {
  API_ENDPOINT_URL: 'https://earlything.org/api/v1',
  APP_PREFIX: '/client',
};

const getEnv = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'development':
      return dev;
    case 'staging':
      return staging;
    case 'production':
      return prod;
    case 'test':
      return test;
    default:
      break;
  }
};

export const env = getEnv();
