import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Select } from 'antd';
import { changeTimezone } from '../../../redux/actions/BoxOffice';
import moment from 'moment-timezone';

const appTimeZones = ['America/New_York', 'Europe/London'];
var zone_name = moment.tz.guess();
// console.log(zone_name);
const timezonesValues = appTimeZones.includes(zone_name)
  ? appTimeZones
  : [zone_name, ...appTimeZones];

const TimeZoneSelection = ({ siteTimezone, changeTimezone }) => {
  const { Option } = Select;
  const onTimeZoneChange = (value) => {
    changeTimezone(value);
  };

  useEffect(() => {
    changeTimezone(zone_name);
  }, [changeTimezone]);

  return (
    <>
      <label style={{ color: '#1a3353', paddingRight: '8px' }}>Time Zone</label>{' '}
      <Select
        placeholder="Time Zone:"
        style={{ width: 200 }}
        onChange={onTimeZoneChange}
        value={siteTimezone}
      >
        {timezonesValues.map((timezone, index) => (
          <Option key={`tz-${index}`} value={timezone}>
            {timezone.split('_').join(' ')}
          </Option>
        ))}
      </Select>
    </>
  );
};

const mapStateToProps = ({ boxoffice }) => ({
  siteTimezone: boxoffice.timezone,
});

export default connect(mapStateToProps, { changeTimezone })(TimeZoneSelection);
